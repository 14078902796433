import html2pdf from 'html2pdf.js';
import React, { useEffect, useRef, useState } from "react";
import { Col, Modal, Row } from "reactstrap";
import { toast } from "react-hot-toast";
import axios from "axios";
import { ExitModal } from '../../components/exitModal';
import { useCookies } from 'react-cookie';
import { Header } from '../../../components/produtos/header';
import { Footer } from '../../../components/produtos/footer';
import { Filter } from '../../../components/produtos/filter';
import { baseUrlApi } from '../../../api/baseUrl';
import Icone from "../../../assets/icones/icone.png"
import { Api } from "../../../api/apiTabelaPrecos";
function VisualizarTabela({ info, nomeProduto, response, id, loading, setLoading }) {
  const [cookie, setCookie] = useCookies(['authUser'])
  const toastConfig = {
    position: "top-right",
    duration: 3000,
    style: { backgroundColor: "#fff" },
  };
  const obj = cookie.authUser
  const divRef = useRef(null);
  const [modalMunicipios, setModalMunicipios] = useState(false)
  const [municipios, setMunicipios] = useState([])

  const [loadingPdf, setLoadingPdf] = useState(false)
  const handleMunicipios = (municipio) => {
    setMunicipios(municipio)
    setModalMunicipios(true)
  }

  const [visualizar, setVisualizar] = useState(false)
  const [tabela, setTabela] = useState([])


  const organizar = () => {
    setVisualizar(false)
    setTabela([])

    let produtos = info?.nome
    let coberturas = info?.cobertura
    let acomodacaos = info?.acomodacao
    let coparticipacaos = info?.coparticipacao
    let precoVidas = info?.precovida
    let faixas2 = info?.faixa
    let tabelas = [{ nome: [], cobertura: [], acomodacao: [], coparticipacao: [], faixas: [], precoVida: [] }]
    let cont = 0
    let contItem = 0
    let arrayFaixa = []
    if (produtos?.length > 0) {
      for (let index = 0; index <= produtos.length; index++) {
        cont = cont + 1
        let nome = produtos ? produtos[index] ? produtos[index] : '' : ''
        let cobertura = coberturas ? coberturas[index] ? coberturas[index] : '' : ''
        let acomodacao = acomodacaos ? acomodacaos[index] ? acomodacaos[index] : '' : ''
        let coparticipacao = coparticipacaos ? coparticipacaos[index] ? coparticipacaos[index] : '' : ''
        let faixa = faixas2 ? faixas2[index] ? faixas2[index] : [] : []
        let precoVida = precoVidas ? precoVidas[index] ? precoVidas[index] : '' : ''

        if (cont <= 5) {
          if (!(nome == '' && cobertura == '' && acomodacao == '' && coparticipacao == '' && precoVida == '')) {
            tabelas[contItem].nome.push(nome)
            tabelas[contItem].cobertura.push(cobertura)
            tabelas[contItem].acomodacao.push(acomodacao)
            tabelas[contItem].coparticipacao.push(coparticipacao)
            tabelas[contItem].precoVida.push(precoVida)
            arrayFaixa.push(faixa)
          }
        } else {
          tabelas[contItem].nome.push(nome)
          tabelas[contItem].cobertura.push(cobertura)
          tabelas[contItem].acomodacao.push(acomodacao)
          tabelas[contItem].coparticipacao.push(coparticipacao)
          tabelas[contItem].precoVida.push(precoVida)
          arrayFaixa.push(faixa)

          let juntar = juntarNomesIguais(arrayFaixa)
          tabelas[contItem].faixas.push(juntar)
          arrayFaixa = []
          contItem = contItem + 1
          tabelas.push({ nome: [], cobertura: [], acomodacao: [], coparticipacao: [], faixas: [], precoVida: [] })
          cont = 0
        }
        if (index == produtos.length) {
          let juntar = juntarNomesIguais(arrayFaixa)
          tabelas[contItem].faixas.push(juntar)
          arrayFaixa = []
          contItem = contItem + 1


        }
      }
    }

    let tabelasOrdernadas = ordenarDados(tabelas)

    setTabela(tabelas)
    setVisualizar(true)
  }
  useEffect(() => {
    organizar()
  }, [info])
  const ordenarDados = (tabelas) => {
    // Cria um array para armazenar todos os dados com a faixa 0-18
    let dadosArray = [];

    tabelas.forEach(tabela => {
      if (tabela && tabela.faixas && tabela.faixas[0]) {
        // Encontra a faixa 0-18
        const faixa018 = tabela.faixas[0].find(faixa => faixa.id === 0);

        if (faixa018 && faixa018.valor) {
          faixa018.valor.forEach((valor, index) => {
            if (tabela.nome[index] !== undefined) {
              dadosArray.push({
                faixaId: faixa018.id,
                faixaNome: faixa018.nome,
                valor: valor,
                nome: tabela.nome[index],
                cobertura: tabela.cobertura[index] || '',
                acomodacao: tabela.acomodacao[index] || '',
                coparticipacao: tabela.coparticipacao[index] || '',
                precoVida: tabela.precoVida[index] || ''
              });
            }
          });
        }
      }
    });

    // Ordena o array de objetos baseado nos valores da faixa 0-18
    dadosArray.sort((a, b) => a.valor - b.valor);

    // Reorganiza os dados nas propriedades de acordo com a nova ordem
    const sortedData = {
      nome: new Array(dadosArray.length),
      cobertura: new Array(dadosArray.length),
      acomodacao: new Array(dadosArray.length),
      coparticipacao: new Array(dadosArray.length),
      faixas: new Array(dadosArray.length),
      precoVida: new Array(dadosArray.length),
      valores: new Array(dadosArray.length)
    };

    dadosArray.forEach((item, index) => {
      sortedData.nome[index] = item.nome;
      sortedData.cobertura[index] = item.cobertura;
      sortedData.acomodacao[index] = item.acomodacao;
      sortedData.coparticipacao[index] = item.coparticipacao;
      sortedData.faixas[index] = item.faixaNome;
      sortedData.precoVida[index] = item.precoVida;
      sortedData.valores[index] = item.valor;
    });

    return sortedData;
  };

  function juntarNomesIguais(array) {
    const resultado = [];
    const mapNomes = new Map();

    array.forEach((subarray) => {
      subarray.forEach((item) => {
        const nome = item.nome;
        if (!mapNomes.has(nome)) {
          mapNomes.set(nome, { id: item.id, status: item.status, nome, valor: item.valor });
        } else {
          const objExistente = mapNomes.get(nome);
          objExistente.valor.push(...item.valor);
        }
      });
    });

    mapNomes.forEach((obj) => {
      // obj?.valor.sort((a, b) => a - b)

      resultado.push(obj);
    });
    resultado.sort((a, b) => a.id - b.id);

    return resultado;
  }

  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [info]);

  const gerarPDF = async () => {
    setLoadingPdf(true)

    axios.get(`${baseUrlApi}/tabelaPrecos/gerarPdf/${id}`, { responseType: 'blob' })
      .then(response => {
        // Verifica se o blob não está vazio (opcional)
        if (!response.data || response.data.size === 0) {
          setLoadingPdf(false)
          return;
        }
        const blob = new Blob([response.data], { type: 'application/pdf' });

        const fileURL = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = fileURL;
        link.setAttribute('download', 'arquivo.pdf');
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(fileURL);
        setLoadingPdf(false)
      })
      .catch(error => {
      });

  }
  const enviarEmail = async () => {
    toast.loading('Estamos enviado a cotação para seu email.', toastConfig);
 
    let response = await Api.enviarEmail({ id })
    if (!response?.error) {
      toast.success(response?.message, toastConfig);
    } else {
      toast.error(response?.message, toastConfig)
    }
  }


  return (
    <section className="featured-section bgs-cover pt-20  pb-20 rpb-100" style={{ minHeight: 850 }}>
      <div className="" style={{ paddingRight: isDesktop ? 20 : 0, paddingLeft: isDesktop ? 20 : 0 }} >

        {(loading || loadingPdf) && (
          <div
            style={{ height: "100vh", display: "flex", justifyContent: "flex-start", alignItems: "center", flexDirection: 'column', position: "absolute", width: "100%", zIndex: 99999999999999, backgroundColor: `rgb(246, 247, 249, 0.6)` }} >
            {loadingPdf &&
              <span style={{ fontSize: 12, color: 'ccc', marginBottom: 10 }} >PDF sendo gerado...</span>
            }
            <img src={Icone} className="rotating-image" />

          </div>
        )}

        {(!loading) &&
          <>
            {response?.error && (
              <div className={`col-lg-12 col-sm-12 item `} style={{ marginTop: "10px", minHeight: 299, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              >
                Tabela de Preço não encontrada.
              </div>
            )}
            {!response?.error && (tabela[0] && tabela[0]?.nome?.length > 0) && <>


              <Filter gerarPDF={gerarPDF} divRef={divRef} enviarEmail={enviarEmail} />

              <div ref={divRef} style={{ padding: 25 }} >

                <Header info={info} tabela={true} divRef={divRef} />

                <div style={{ fontWeight: 'bold', fontSize: 14, height: 30, display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 30 }} >
                  <img src={`${baseUrlApi}/imagens/${info.imagens[0]?.imagem}`} width='200px' />
                </div>

                {tabela && tabela.map((item, index) => (
                  <div key={index + '-table'} style={{ breakInside: 'avoid' }}>
                    {item?.nome.length > 0 &&
                      <div style={{ width: '100%', backgroundColor: 'rgb(204, 204, 204,0.5)', borderRadius: '6px 6px 0px 0px' }}>
                        <div style={{ borderRadius: 6, fontSize: 15, textAlign: 'center', color: '#092140', fontWeight: 'bold', height: 30 }} >{nomeProduto.toUpperCase()}</div>
                      </div>
                    }
                    <div style={{ overflowX: 'auto' }}>
                      <table key={index} id="originalTable" style={{ marginBottom: 40, width: '100%' }}>
                        <tbody>
                          {(item?.nome?.length > 0 && !item?.nome?.every((element) => element === "")) &&
                            <tr style={{ backgroundColor: 'rgb(204, 204, 204,0.5)', padding: 0 }} >
                              <th style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 16, padding: 5, borderTop: index == 0 ? 'none' : '1px solid #fff' }} >Produto</th>

                              {item && item.nome?.map((item2, index2) => (
                                <th key={`Nome-${index2}-${index}`} style={{ textAlign: 'center', fontWeight: 100, padding: 5, borderTop: index == 0 ? 'none' : '1px solid #fff' }} >{item2}</th>
                              ))}


                            </tr>
                          }
                          {item?.faixas?.length == 0 && item?.precoVida?.length > 0 && !item?.precoVida?.every((element) => element === "") &&
                            <tr style={{ backgroundColor: 'rgb(204, 204, 204,0.5)', padding: 0 }} >
                              <th style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 16, padding: 5, borderTop: '1px solid #fff' }} >Preço/Vida</th>
                              {item && item?.precoVida?.map((item2, index2) => (
                                <th key={`Preco-${index2}-${index}`} style={{ textAlign: 'center', fontWeight: 100, padding: 5, borderTop: '1px solid #fff' }} >  {item2 ? `R$ ${item2}` : ''}</th>
                              ))}
                            </tr>
                          }

                          {item?.cobertura?.length > 0 && !item?.cobertura?.every((element) => element === "") &&
                            <tr style={{ backgroundColor: 'rgb(204, 204, 204,0.5)' }}>
                              <th style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 16, padding: 5, borderTop: '1px solid #fff' }} >Cobertura</th>
                              {item && item?.cobertura?.map((item2, index2) => (
                                <th key={`cobertura-${index2}-${index}`} style={{ textAlign: 'center', fontWeight: 100, padding: 5, borderTop: '1px solid #fff' }} >{item2}</th>
                              ))}
                            </tr>
                          }
                          {item?.acomodacao?.length > 0 && !item?.acomodacao?.every((element) => element === "") &&
                            <tr style={{ backgroundColor: 'rgb(204, 204, 204,0.5)' }}>
                              <th style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 16, padding: 5, borderTop: '1px solid #fff' }} >Acomodação</th>
                              {item && item?.acomodacao?.map((item2, index2) => (
                                <th key={`acomodacao-${index2}-${index}`} style={{ textAlign: 'center', fontWeight: 100, padding: 5, borderTop: '1px solid #fff' }} >{item2}</th>
                              ))}
                            </tr>
                          }
                          {item?.coparticipacao?.length > 0 && !item?.coparticipacao.every((element) => element == "") &&
                            <tr style={{ backgroundColor: 'rgb(204, 204, 204,0.5)' }}>
                              <th style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 16, padding: 5, borderTop: '1px solid #fff' }} >Coparticipação</th>
                              {item && item?.coparticipacao?.map((item2, index2) => (
                                <>
                                  {item2.nome &&
                                    <th key={`Coparticipacao-${index2}-${index}`} style={{ textAlign: 'center', fontWeight: 100, padding: 5, borderTop: '1px solid #fff' }} >{item2.status ? '' + `${item2.nome.includes('Parcial') ? ' Parcial' : ''}` + `${item2.nome.includes('Total') ? ' Total' : ''}` + `${(item2.nome.includes('Sim') && !item2.percentual) ? ' Sim' : ''}` : 'Sem coparticipacão'}  {(item2.status && item2.percentual) ? 'de ' + item2.percentual + '%' : ''} </th>
                                  }
                                </>
                              ))}
                            </tr>
                          }
                          {item?.faixas?.length > 0 ? item?.faixas.filter((fill) => fill?.some((som) => som?.valor?.some((som2) => som2?.valor)))?.map((item4, cont1) => (
                            <>
                              {item4.map((item5, cont2) => (
                                <tr key={`Faixa-${cont1}-${cont2}`} >

                                  <th style={{ textAlign: 'center', borderTop: '1px solid #fff' }} >{item5.nome.replace('-', ' à ')}</th>
                                  {item5.valor?.filter((fill) => fill?.valor)?.map((item6, cont3) => (
                                    <th key={`Faixas-${cont1}-${cont2}-${cont3}`} style={{ textAlign: 'center', borderTop: '1px solid #fff' }} >
                                      {item5.status != 1 ?
                                        <span style={{ color: 'red' }}>
                                          {item5.status == 2 && 'Comercialização suspensa'}
                                          {item5.status == 3 && 'Atualizando'}
                                          {item5.status == 4 && 'Suspenso'}
                                        </span>
                                        :
                                        <>

                                          {item6.status ? `${(parseFloat(item6.valor))?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}` : 'Suspenso'}
                                        </>
                                      }
                                    </th>
                                  ))}
                                </tr>
                              ))}
                            </>
                          )) : ''}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ))}

                <Footer visualizar={true} tabelas={info} municipio={municipios} operadora={''} />
              </div>
            </>}
            <div className="feature-btn text-center mt-20"></div>
          </>

        }

      </div>


      <Modal isOpen={modalMunicipios} centered={true}>
        <div className="modal-header" style={{ border: 'none' }}>
          <ExitModal setModal={setModalMunicipios} />
        </div>
        <div className="modal-body" style={{ paddingLeft: 30, paddingRight: 30, paddingBottom: 20 }}>
          <p style={{ color: '#092140', textAlign: 'center' }}>Municípios</p>
          <Row style={{ justifyContent: 'center', alignItems: 'center' }} >
            {municipios.map((item3, index) => (
              <span key={`Comercializacao-${index}`} dangerouslySetInnerHTML={{ __html: `${decodeURIComponent(item3.munici)} -` }}  ></span>
            ))}
          </Row>
        </div>
      </Modal>
    </section>
  );
}

export default VisualizarTabela;
